<template>
  <b-modal
    :title="$t('link.description-input.title')"
    :visible="visible"
    hide-footer
    @change="$emit('change', false)"
  >
    <template>
      <h4>{{ title }}</h4>
      <hr>
      <h5>{{ description }}</h5>
    </template>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'DescriptionInput',
  components: { BModal },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>
